.App {
  text-align: center;
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Button{
  position: absolute;
  z-index: 2000;
  width: 8vw;
  bottom:31.5vh;
  left:50%;
  min-width: 120px;
  transform: translate(-50%,50%);
  box-sizing: border-box;
  padding: 0.9vh 0.5vh 0.9vh 0.5vh;
  font-size: 1.1em;
  color:white;
  background-color:slategray;
  border:black 0.1vh solid;
  border-radius: 5px;
  
}
.Button :hover{
  border:none;
}
.Lottie{
  /* position: absolute;
  height: 70%; */
}
